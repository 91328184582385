/* =========== ChatRoom =============== */

.chatroom-wrapper {
    height: 100%;
    width: 100%;
    float: right !important;
}

.chatroom {
    margin: 0 !important;
    padding: 0 !important;
    width: 33.33%;
    height: 100%;
    position: fixed;
    right: 0;
}

.event-row {
    margin: 0 !important;
    padding: 0 !important;
}

.message-list {
    width: 100%;
    height: 70%
}
.msg-username {
   color: #795548;
   font-weight: 600;

}

.chatroom-topbar {
    padding: 1rem 2rem;
}

.chat-body {
    width: 100%;
    height: 100%;
    display: block !important;
    padding-bottom: 0.4rem;;
}

.chat-timestamp {
    font-size: 0.8rem;
    margin-right: 0.5rem
}

span.material-icons {
    font-size: 20px;
    padding-right: 0.2rem
}

.account-circle {
    color: #ff5252 !important;
}

.textbox {
    /* margin: 0.4rem auto; */
    padding: 0 1.2rem 0.8rem;
    width: 100%;
}

.textbox-input {
    max-width: 80% !important
}

.chat-username {
    font-size: 0.8rem
}

.chat-body-avatar-div {
    display: inline;
    width: 10%;
    vertical-align: middle;
    font-size: 0.8rem
}
.chat-body-text-div{
 display: inline;
 width: 90%;
 margin-left: 0.8rem;
 text-align: left;   
}
.chat-avatar {
    cursor: auto;
    box-shadow: none;
    vertical-align: middle;
    width: 1.4rem;
    height: 1.4rem;
    font-size: 0.8rem;
    line-height: 1.6rem;
}

/* =========== NavBar =============== */

nav {
    position: fixed;
    top: 0;
    z-index: 99;
}

/* =========== Player =============== */

/* .dropdown-content {
    display: block;
    position: relative;
    top: 20px !important;
    left: 440px !important;
    z-index: 24;
} */

.icon-more {
    color: #ff5252 !important;
    position: relative;
    top: 30px;
    z-index: 20;
}

.dropdown-content {
    z-index: 20;
}

#twitch-0, #twitch-1, #twitch-2, #twitch-3, #twitch-4, #twitch-5, #twitch-6, #twitch-7, #twitch-8 {
    width: 100%;
    height: 100%;
}

/* =========== EventDetailsTop =============== */

p .s-magrin {
    margin-top: 0;
    margin-bottom: 8px
}

.event-details-icons {
    color: #ff5252 !important;
    margin-top: 0.5rem;
    margin-left: 1rem
}

.event-details a {
    cursor: pointer;
}

#add-url-row .input-field {
    margin: 0.2rem 0;
    width: 90%;
    display: inline-block;
}

.event-title-row {
    margin-bottom: 0
}

.delete-btn:click {
    background-color: grey !important
}

.event-detail-top-btn {
    margin-left: 1rem;
}

.row .col {
    padding: 0 0.4rem;
}

@media screen and (min-width: 480px) {
    .player-wrapper {
        height: 30rem;
    }
}

.player-wrapper {
    width: 100%;
    /* height: 20rem; */
    margin-bottom: 1rem
}

.event-left {
    /* margin: 2rem 2rem; */
    height: 100%;
    padding: 0 2rem !important;
}

.event-details-title {
    font-size: 1.6rem;
    display: inline-block;
}

/* =========== Dashboard =============== */

.dashboard {
    padding: 0 2rem !important;
}

/* =========== SignUp =============== */

[type="checkbox"].filled-in:checked+span:not(.lever):after {
    background-color: #ff5252 !important;
    border: #ff5252 !important;
}

.signup-input-column{
    margin-right: 2rem
}

.container {
    margin: 4em auto;
}

.text {
    color: #424242;
}

.button {
    margin-top: 4rem;
}

.nobull {
    list-style-type: none;
}

.navbar {
    margin: 0 2rem;
}

input:focus {
    border-bottom: 2px solid #ff5252 !important;
    -webkit-box-shadow: none !important;
}

textarea:focus {
    border-bottom: 2px solid #ff5252 !important;
    -webkit-box-shadow: none !important;
}

label.active {
    color: #ff5252 !important;
}

/* =========== SignIn =============== */

.pad-container {
    padding-top: 4rem
}

/* =========== EventSummary =============== */

.custom-card-title {
    font-size: 1rem;
    font-weight: 400
}
.card-subtitle{
    font-size: 0.8rem;
}
.card .card-content{
    padding: 1rem;
}
.ev-absolute{
    position: absolute!important;
}
.ev-pd-x-05 {
    padding-left: .5rem!important;
    padding-right: .5rem!important;
}
.ev-mg-05{
    margin:0.5rem;
}
.ev-strong{
    font-weight: 600;
}
.zindex-99{
    z-index: 99;
}
.ev-top-0 {
    top: 0
}
.ev-left-0{
    left:0 
}
.ev-bottom-0{
    bottom:0 
}
/* =========== Footer =============== */

.input-field.col {
    padding: 0;
}

.contact-col {
    padding: 0 !important
}

/* =========== Footer =============== */

footer {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;
}

.page-footer {
    padding: 0;
    margin: 0
}

@media (max-width:500px) {
    .page-footer li {
        float: none;
    }
}

.page-footer li {
    float: right
}

.page-footer li a {
    display: block;
    padding: 0 4rem;
}

/* overwrite materialize css */
.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,0.05), 0 1px 1px -1px rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05), 0 1px 1px -1px rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.1);
    /* 0 1px 1px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2); */
}